import { createApp } from 'vue'
import App from '@/App.vue'
import MainLayout from '@/layouts/Main/MainLayout.vue'
import AuthLayout from '@/layouts/Auth/AuthLayout.vue'
import Dashboard from '@/views/DashBoard.vue'
import LoginView from '@/views/auth/LoginView.vue'
import ReportIndex from '@/views/report/IndexView.vue'

import { createRouter, createWebHistory } from 'vue-router'

const app = createApp(App)

let routes = [
  //Dashboard
  {
    path: '/',
    name: 'home',
    component: MainLayout,

    children: [
      {
        name: 'default',
        path: '/',
        component: Dashboard
      }
    ]
  },
  //Auth
  {
    path: '/auth',
    name: 'auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: '',
        component: LoginView
      }
    ]
  },
  //Group-permissions
  {
    path: '/group-permissions',
    name: 'group-permissions',
    component: MainLayout,

    children: [
      {
        name: 'group-permissions-index',
        path: '',
        component: import('@/views/group_permissions/IndexView.vue')
      },
      {
        name: 'group-permissions-create',
        path: '',
        component: import('@/views/group_permissions/CreateView.vue')
      },
      {
        name: 'group-permissions-edit',
        path: '',
        component: import('@/views/group_permissions/EditView.vue')
      },
      {
        name: 'group-permissions-show',
        path: '',
        component: import('@/views/group_permissions/ShowView.vue')
      },
    ]
  },
  // Report
  {
    path: '/reports',
    name: 'report',
    component: MainLayout,
    children: [
      {
        name: 'report-index',
        path: '',
        component: ReportIndex
      }
    ]
  },

]


//  -----------definir de forma dinamica as rotas---------------
let dynamicRoutes = [
  'cfop',
  'deadlines',
  'groups',
  'holidays',
  'interest-types',
  'invoices',
  'invoice-statuses',
  'invoice-lines',
  'invoice-deliveries',
  'invoice-steps',
  'ncms',
  'occurrences',
  'occurrence-types',
  'assets',
  'participants',
  'permissions',
  'users',
  'vehicles',
  'km-ranges',
  'limits',
  'reasons',
  'capacities',
  'active-vouchers',
  'process',
  'ctes'
];

dynamicRoutes.forEach( (routeName) => {
    routes.push({
        path:'/'+ routeName,
        name: routeName,
        component: MainLayout,
        children:[
          {
              name: routeName + '-index',
              path: '/' + routeName ,
              component: () => import('@/views/' + routeName.replace('-','_') + '/IndexView.vue'),
          },
          {
              name: routeName + '-create',
              path: '/' + routeName + '/create',
              component: () => import('@/views/' + routeName.replace('-','_') + '/CreateView.vue'),
          },
          {
              name: routeName + '-edit',
              path: '/' + routeName + '/edit/:id',
              component: () => import('@/views/' + routeName.replace('-','_') + '/EditView.vue'),
          },
        ],
    })
});

const router = createRouter({
  history: createWebHistory(),
  routes: routes
})

app.use(router)

export default router
