<template>
  <div>
    <div id="content-page" class="content-page">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="iq-card">
              <div class="iq-card-header d-flex justify-content-center">
                <div class="iq-header-title">
                  <h4 class="card-title">Relatorio geral</h4>
                </div>
              </div>
              <div class="iq-card-body">

              <div class="new-user-info">
                <form @submit.prevent="generateReport">
                  <div class="row">

                    <div class="form-group col-md-3">
                      <label for="lname">Embarcador:</label>
                      <select  v-model="reportfilter.issuer" class="form-control">
                        <option value="">Selecione</option>
                        <option  v-for="issuer in issuers " :key="issuer.id" :value="issuer.id">
                          {{ formatCNPJ(issuer.cnpj) +' - ' + issuer.company_name }}
                        </option>
                      </select>
                    </div>


                    <div class="form-group col-md-3">
                      <label for="lname">Transportadora:</label>
                      <select v-model="reportfilter.transporter" class="form-control">
                        <option value="">Selecione</option>
                        <option v-for="transporter in allTransporters " :key="transporter.id" :value="transporter.id">
                          {{ formatCNPJ(transporter.cnpj) +' - ' + transporter.company_name }}
                        </option>
                      </select>
                    </div>

                    <div class="form-group col-md-3">

                      <label for="lname">Data de Emissão:</label>
                      <Datepicker v-model="reportfilter.emission_date" range :enable-time-picker="false" />

                    </div>

                    <div class="form-group col-md-3">

                    <label for="lname">Data de saida:</label>
                    <Datepicker v-model="reportfilter.departure_date" range :enable-time-picker="false" />

                    </div>
                  </div>
                  <router-link
                    :to="{ name: 'cfop-index' }"
                    class="btn btn-danger mr-1"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Cancelar"
                  >
                    Cancelar
                  </router-link>
                  <button type="submit" class="btn btn-primary text-white">
                    Gerar Relatorio
                  </button>
                </form>
              </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ConfirmModal from '@/components/utils/modal/confirm-modal.vue'

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import { ref, onMounted } from 'vue'

</script>
<script>
import axios from 'axios'
import { core } from '@/config/pluginInit'
import { toast } from 'vue3-toastify'

export default {
  data() {
    return {
      headers: [
        {title:'Cod', value:'cod_cfop'},
        {title:'Descrição', value:'description'},
        {title:'Status', value:'status'},
        {title:'Ações', key: 'actions'},

      ],
      indexData: [],
      allTransporters: [],
      issuers: [],
      searchQuery: '',
      reportfilter:{
        transporter:'',
        issuer:'',
        departure_date:ref(),
        emission_date:ref(),
        issuer_date:'',
      },
      pagination: {
        labelItensPerPage: '',
        loading:false,
        itemsPerPage:10,
        totalItems: 10,
        page:1,
          paginateItensPerpageOptions: [
            {value: 10, title: '10'},
            {value: 25, title: '25'},
            {value: 50, title: '50'},
        ]
      }
    }
  },
  mounted() {
    core.index()

    axios
      .get(`/api/transporters`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
      .then((response) => {
        this.allTransporters = response.data.data;

      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          toast.error('Faça o login !');
          this.$router.push('/auth')
        }
      })
    axios
      .get(`/api/shippers`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
      .then((response) => {
        this.issuers = response.data.data;
        console.log(response.data.data)

      })
      .catch((error) => {
        console.error('Error fetching user data:', error)
        if (error.response && error.response.status === 401) {
              toast.error('Faça o login !');
              this.$router.push('/auth')
        }
      })
  },
  methods: {

    formatCNPJ(cnpj) {
      return cnpj.length === 14
        ? `${cnpj.substring(0, 2)}.${cnpj.substring(2, 5)}.${cnpj.substring(5, 8)}/${cnpj.substring(8, 12)}-${cnpj.substring(12, 14)}`
        : cnpj;
    },
    generateReport() {
      axios
      .get(`/api/report`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        responseType:'blob',
        params: this.reportfilter
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
        const link = document.createElement('a');
        link.href = url;

        const d = new Date();
        const year = d.getFullYear();
        const month = d.getMonth() + 1;
        const day = d.getDate();

        link.download = `RelatorioGeral-${day}-${month}-${year}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

      })
      .catch((error) => {
        console.error('Error fetching user data:', error)
        if (error.response && error.response.status === 401) {
              toast.error('Faça o login !');
              this.$router.push('/auth')
        }
      })

    },
    getLabelItensPerPage() {
      let pagination = this.pagination;
      let from = pagination.page * pagination.itemsPerPage - pagination.itemsPerPage + 1;
      let to = (pagination.page * pagination.itemsPerPage > pagination.totalItems )? pagination.totalItems: pagination.page * pagination.itemsPerPage;
      this.pagination.labelItensPerPage = from + ' - ' + to + ' de ' + pagination.totalItems;
    },
    fetchData() {

      let page = this.pagination.page;
      let itensPerPage = this.pagination.itemsPerPage;
      this.pagination.loading= true;
      axios
      .get( '/api/' + this.$route.name.replace('-index','') + `?itensPerPage=${itensPerPage}&page=${page}&search=${this.searchQuery}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
      .then((response) => {
          this.pagination.loading= false;
          this.pagination.totalItems= response.data.data.total;
          this.pagination.page= page;
          this.getLabelItensPerPage();
          this.indexData = response.data.data.data
        })
        .catch((error) => {
          console.error('Error fetching cfop:', error)

          if (error.response && error.response.status === 401) {
              toast.error('Faça o login !');
              this.$router.push('/auth')
          }
        })
    },
    destroy(destroyId) {
      axios
        .delete( '/api/' + this.$route.name.replace('-index','') + '/'  + destroyId, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        })
        .then((response) => {
          toast.success('Item deletado com sucesso!');
          this.fetchData();
        })
        .catch((error) => {
          if (error.response.status == 409) {
            toast.error('Existe entidades associadas com esse item!');
            this.fetchData();
            return
          }

          if (error.response && error.response.status === 401) {
              toast.error('Faça o login !');
              this.$router.push('/auth')
          }

          toast.error('Ocorreu um erro inesperado!');
          this.fetchData();
        });
    },
  }
}
</script>
